<template>
  <div v-if="isLoading" id="preloader">
    <div class="spinner-border color-highlight" role="status"></div>
  </div>

  <!-- Start Page -->
  <div id="page" class="">
    <!-- Footer -->
    <Footer logged />
    <!--End Footer -->

    <!-- Page Content -->
    <div class="page-content pb-0">
      <div data-card-height="cover-full" class="card mb-0">
        <div class="card-center">
          <div class="text-center">
            <p class="font-600 color-highlight mb-1 font-16">PasJob</p>
            <h1 class="font-40 color-highlight">Login</h1>
          </div>

          <div class="content px-4">
            <p v-if="errors" class="color-red-dark mb-3">
              {{ errors.errorMsg }}
            </p>
            <div class="input-style no-borders has-icon validate-field mb-4">
              <i class="fa fa-user"></i>
              <input
                type="email"
                class="form-control validate-name"
                id="form1a"
                placeholder="email@example.com"
                v-model="email"
                required
              />
              <label for="form1a" class="color-highlight">Email</label>
              <i class="fa fa-times disabled invalid color-red-dark"></i>
              <i class="fa fa-check disabled valid color-green-dark"></i>
              <em
                :class="{
                  'color-red-dark font-800 req-val': errors.email,
                }"
                >(required)</em
              >
            </div>
            <div class="input-style no-borders has-icon validate-field mb-4">
              <i class="fa fa-lock"></i>
              <input
                :type="showPassword === true ? 'text' : 'password'"
                class="form-control validate-password"
                id="form1ab"
                placeholder="Password"
                v-model="password"
                required
                autocomplete="section-blue shipping address-level2"
              />
              <label for="form1ab" class="color-highlight">Password</label>
              <i class="fa fa-times disabled invalid color-red-dark"></i>
              <i class="fa fa-check disabled valid color-green-dark"></i>
              <em
                :class="{
                  'color-red-dark font-800 req-val': errors.password,
                }"
                >(required)</em
              >
            </div>
            <div class="form-check icon-check">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="check4"
                v-model="showPassword"
              />
              <label class="form-check-label" for="check4">
                Lihat password
              </label>
              <i class="icon-check-1 far fa-square color-gray-dark font-16"></i>
              <i
                class="icon-check-2 far fa-check-square font-16 color-highlight"
              ></i>
            </div>

            <button
              @click.prevent="submitForm"
              class="btn btn-center-l btn-l font-600 font-13 gradient-highlight mt-4 rounded-s"
            >
              Masuk
            </button>

            <div class="row pt-3 mb-3">
              <div class="col-6 text-start font-11">
                <router-link
                  :to="{ name: 'ResetPassword' }"
                  class="color-highlight"
                  >Lupa Password ?</router-link
                >
              </div>
              <div class="col-6 text-end font-11">
                <!---
                <router-link to="/sign-up" class="color-highlight">
                  Create Account
                </router-link>
                -->
                <router-link
                  :to="{
                    name: 'CreateConfirm',
                    query: { invite_code: inviteCode },
                  }"
                  class="color-highlight"
                >
                  Buat Akun</router-link
                >
              </div>
            </div>

            <!-- <a
                href="#"
                class="mb-3 text-start btn btn-m btn-full bg-white btn-icon font-600"
                ><i class="fab fa-google font-16 text-center"></i
                ><span class="color-gray"> Login dengan Google</span></a
              > -->
            <div class="d-flex d-flex flex-column mb-5">
              <div
                @click="googleSignIn"
                class="align-self-center"
                id="gSignInWrapper"
              >
                <div id="customBtn" class="customGPlusSignIn">
                  <span class="icon"></span>
                  <span class="buttonText">Login dengan Google</span>
                </div>
              </div>
              <div id="name"></div>
            </div>
            <router-link
              :to="{ name: 'TanyaPasjob' }"
              class="d-flex flex-column fw-bold"
            >
              <span class="color-gray-dark align-self-center"
                >Ada kendala/pertanyaan?</span
              >
            </router-link>

            <a
              href="https://landbot.online/v3/H-1614658-Y6GMJ5J2YESMJ88G/index.html"
              class="d-flex flex-column fw-bold"
            >
              <span class="color-highlight align-self-center"
                >Tanya Pasjob.</span
              >
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- End Page Content-->
    <div @click="menuClose" class="menu-hider"></div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import { init_template, menuClose } from "@/components/menu/index.js";
import { getMessaging, getToken } from "firebase/messaging";
/*import { preloader, load } from "@/components/script/custom.js";*/

import Footer from "@/components/Footer.vue";

export default {
  name: "SignIn",
  inject: ["messaging"],
  data() {
    return {
      email: "",
      password: "",
      errors: [],
      isLoading: true,
      inviteCode: this.$route.query.invite_code,
      deviceId: "",
      showPassword: false,
    };
  },
  mounted() {
    init_template();
    setTimeout(() => {
      this.isLoading = false;
    }, 150);
    console.log("Firebase cloud messaging object", this.messaging);
    this.getUserId();
  },
  components: {
    Footer,
  },
  methods: {
    // function close for men
    getUserId() {
      getToken(this.messaging)
        .then((currentToken) => {
          if (currentToken) {
            this.deviceId = currentToken;
          } else {
            // Show permission request UI
            console.log(
              "No registration token available. Request permission to generate one."
            );
            // ...
          }
        })
        .catch((err) => {
          console.log("An error occurred while retrieving token. ", err);
          // ...
        });
    },
    menuClose,
    signIn(response) {
      const user_type = response.data.user_type;
      const token = response.data.tokens.access;
      const refresh = response.data.tokens.refresh;

      const accountData = {
        userType: user_type,
        isVerified: response.data.is_verified,
        email: response.data.email,
      };

      axios.defaults.headers.common["Authorization"] = "Bearer " + token;

      this.$store.commit("addAccount", accountData);
      localStorage.setItem("token", token);
      localStorage.setItem("refresh", refresh);

      this.$store.commit("setToken", token);
      this.$store.commit("setRefresh", refresh);
      this.$store.commit("setUserType", user_type);

      if (user_type === "J") {
        this.getUserProfile();
      }

      const toPath = this.$route.query.to || "/dashboard";
      this.$router.push(toPath);
    },
    async googleSignIn() {
      try {
        const googleUser = await this.$gAuth.signIn();
        if (!googleUser) {
          return null;
        }
        this.user = googleUser.getBasicProfile().getEmail();
        if (googleUser.getAuthResponse) {
          const formData = {
            auth_token: googleUser.getAuthResponse().id_token,
          };
          console.log(formData);
          await axios
            .post("/api/v1/social-auth/google/", formData)
            .then((response) => {
              this.signIn(response);
            })
            .catch((error) => {
              console.log("error : ", error);
            });
        }
      } catch (error) {
        console.error(error);
        return null;
      }
    },

    /* get User profile id*/
    async getUserProfile() {
      this.errors = [];
      await axios
        .get("/api/v1/job-seeker/profile")
        .then((response) => {
          if (response.data.length === 1) {
            var id = response.data[0].id;
            localStorage.setItem("userProfileId", id);
            this.$store.commit("setId", id);
            this.$store.commit("addApply", response.data[0].apply_user);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log(
              "response data : " + JSON.stringify(error.response.data)
            );
          } else if (error.message) {
            this.errors.push("Something went wrong please try again");
            console.log();
            console.log("error :" + JSON.stringify(error));
          }
        });
    },

    validateInput() {
      this.errors = {};
      var dataInput = {
        email: this.email,
        password: this.password,
      };

      // validate email input
      let re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (!re.test(this.email)) {
        this.errors["email"] = "masukkan email yang valid";
        this.errors["errorMsg"] = "masukkan email yang valid";
      }

      // input not empty check
      for (const key in dataInput) {
        if (dataInput[key] === "") {
          this.errors[key] = "Empty Input";
          this.errors["errorMsg"] = "Pastikan sudah mengisi semua form";
        }
      }
      if (Object.keys(this.errors).length) {
        window.scroll({
          top: 0,
          behavior: "smooth",
        });
      }
    },

    // form login
    async submitForm() {
      this.validateInput();
      if (!Object.keys(this.errors).length) {
        axios.defaults.headers.common["Authorization"] = "";
        localStorage.removeItem["token"];
        this.errors = [];

        const formData = {
          email: this.email,
          password: this.password,
          device_id: this.deviceId,
        };
        console.log(formData);

        await axios
          .post("/api/v1/auth/login/", formData)
          .then((response) => {
            this.signIn(response);
          })
          .catch((error) => {
            if (error.response) {
              this.errors["errorMsg"] = "email atau password salah";
              console.log(JSON.stringify(error.response.data));
            } else if (error.message) {
              this.errors.push("Something went wrong please try again");
              console.log(JSON.stringify(error));
            }
          });
      }
    },
  },
};
</script>

<style scoped>
#customBtn {
  display: inline-block;
  background: white;
  color: #444;
  width: 250px;
  border-radius: 5px;
  border: thin solid #8888884f;
  /* box-shadow: 1px 1px 1px grey; */
  white-space: nowrap;
}
#customBtn:hover {
  cursor: pointer;
}
span.label {
  font-family: serif;
  font-weight: normal;
}
span.icon {
  background: url("https://developers-dot-devsite-v2-prod.appspot.com/identity/sign-in/g-normal.png")
    transparent 5px 50% no-repeat;
  display: inline-block;
  vertical-align: middle;
  width: 42px;
  height: 42px;
}
span.buttonText {
  display: inline-block;
  vertical-align: middle;
  padding-left: 22px;
  padding-right: 22px;
  font-size: 14px;
  font-weight: bold;
  /* Use the Roboto font that is loaded in the <head> */
  font-family: "Roboto", sans-serif;
}
</style>